<template scoped>
  <div :style="{ height: '100%' }">
    <el-page-header
      :style="{ margin: '10px' }"
      @back="back"
      :content="getTitle"
    >
    </el-page-header>

    <el-form
      :style="{
        padding: '10px',
        margin: '10px',
        height: 'calc(100% - 80px)',
        overflowY: 'auto',
        background: '#ffffff',
      }"
      status-icon
      ref="detail"
      class="config"
      :model="provider"
      label-width="160px"
    >
      <el-alert
        v-if="success"
        :title="getStatusTitle"
        type="success"
        :description="getDescription"
        show-icon
      >
      </el-alert>

      <el-form-item
        label="Name"
        prop="name"
        :rules="rules.name"
        :style="{ marginTop: '10px' }"
      >
        <el-input v-model="provider.name"></el-input>
      </el-form-item>

      <el-form-item
        label="Type"
        prop="type"
        :rules="rules.type"
        :style="{ marginTop: '10px' }"
      >
        <el-select
          @change="action_change"
          v-model="provider.type"
          :style="{ width: '100%' }"
          placeholder="Select the Action"
        >
          <el-option
            v-for="item in supportType"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <component
        :is="content"
        :endpoint="endpoint"
        :provider="provider"
      ></component>

      <el-form-item>
        <el-button type="primary" @click="create" v-if="provider.id.length == 0"
          >Create</el-button
        >
        <el-button type="primary" @click="update" v-if="provider.id.length > 0"
          >Update</el-button
        >
        <el-button @click="back">Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    toPage: Function,
    endpoint: String,
    parameter: Object,
  },
  data: function () {
    return {
      provider: {
        id: "",
        name: "",
        type: null,
        mobile: {
          provider: null,
          azureNotificationHub: {
            name: null,
            connectionString: null,
          },
        },
        email: {
          provider: null,
          sendGrid: {
            apiKey: null,
            senderAddress: null,
          },
          smtp: {
            host: null,
            port: "587",
            username: null,
            password: null,
            ssl: true,
          },
        },
        sms: {
          provider: null,
          accessYou: {
            accountNumber: null,
            password: null,
          },
        },
      },
      content: null,
      success: false,
      rules: {
        name: [{ validator: this.validator_name, trigger: "blur" }],
        type: [{ validator: this.validator_type, trigger: "blur" }],
      },
      supportType: [
        {
          name: "Email",
          value: "Email",
        },
        {
          name: "SMS",
          value: "SMS",
        },
        {
          name: "Mobile",
          value: "Mobile",
        },
        {
          name: "Line",
          value: "Line",
        },
        {
          name: "Telegram",
          value: "Telegram",
        },
        {
          name: "WhatsApp",
          value: "WhatsApp",
        },
      ],
    };
  },
  computed: {
    getDescription: function () {
      return `instanceId # ${this.provider.id}`;
    },
    getStatusTitle: function () {
      if (this.provider.id) {
        return "Update Success";
      } else {
        return "Create Success";
      }
    },
    getTitle: function () {
      if (this.provider.id) {
        return "Edit Provider";
      } else {
        return "Create Provider";
      }
    },
  },
  created: function () {
    if (this.parameter) {
      this.provider.id = this.parameter.providerId;

      var $this = this;

      fetch(`${this.endpoint}/api/Backend/Provider/${this.provider.id}`)
        .then(function (request) {
          return request.json();
        })
        .then(function (response) {
          if (response.statusCode == 200) {
            $this.provider.name = response.result.name;
            $this.provider.type = response.result.type;
            $this.provider.mobile.provider = response.result.mobile.provider;
            $this.provider.mobile.azureNotificationHub.name =
              response.result.mobile.azureNotificationHub.name;
            $this.provider.mobile.azureNotificationHub.connectionString =
              response.result.mobile.azureNotificationHub.connectionString;
            $this.provider.email.provider = response.result.email.provider;
            $this.provider.email.sendGrid.apiKey =
              response.result.email.sendGrid.apiKey;
            $this.provider.email.sendGrid.senderAddress =
              response.result.email.sendGrid.senderAddress;
            $this.provider.email.smtp.host = response.result.email.smtp.host;
            $this.provider.email.smtp.port = response.result.email.smtp.port;
            $this.provider.email.smtp.username =
              response.result.email.smtp.username;
            $this.provider.email.smtp.password =
              response.result.email.smtp.password;
            $this.provider.email.smtp.ssl = response.result.email.smtp.ssl;

            $this.provider.sms.provider = response.result.sms.provider;
            $this.provider.sms.accessYou.accountNumber =
              response.result.sms.accessYou.accountNumber;
            $this.provider.sms.accessYou.password =
              response.result.sms.accessYou.password;

            $this.action_change($this.provider.type);
          }
        });
    }
  },
  methods: {
    action_change: function (val) {
      switch (val) {
        case "Mobile":
          this.content = () =>
            import("./Mobile/Panel");
          break;
        case "SMS":
          this.content = () =>
            import("./SMS/Panel");
          break;
        case "Email":
          this.content = () =>
            import("./Email/Panel");
          break;
        default:
          this.content = null;
          break;
      }
    },
    back: function () {
      this.toPage(
         "components/Provider/Portal-Provider.vue"
      );
    },
    validator_name: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the name should not be empty"));
      } else {
        callback();
      }
    },
    validator_type: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the type should not be empty"));
      } else {
        callback();
      }
    },
    update: function () {
      var $this = this;
      this.$refs.detail.validate((valid) => {
        if (valid) {
          fetch(`${this.endpoint}/api/Backend/Provider/${this.provider.id}`, {
            method: "PUT",
            body: JSON.stringify($this.provider),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(function (request) {
              return request.json();
            })
            .then(function (response) {
              if (response.statusCode == 200) {
                $this.success = true;
              }
            });
        }
      });
    },
    create: function () {
      var $this = this;
      this.$refs.detail.validate((valid) => {
        if (valid) {
          fetch(`${this.endpoint}/api/Backend/Provider`, {
            method: "POST",
            body: JSON.stringify($this.provider),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(function (request) {
              return request.json();
            })
            .then(function (response) {
              if (response.statusCode == 200) {
                $this.success = true;
                $this.provider.id = response.result;
              }
            });
        }
      });
    },
  },
};
</script>
